var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Ajouter vendeur"}},[_c('b-card-text',[_c('span',[_vm._v("Veuillez Saisir les Coordonnées de vendeur ")])]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nom","label-for":"nom"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Name"},model:{value:(_vm.vendor.name),callback:function ($$v) {_vm.$set(_vm.vendor, "name", $$v)},expression:"vendor.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"Email"}},[_c('validation-provider',{attrs:{"name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Email","type":"email"},model:{value:(_vm.vendor.email),callback:function ($$v) {_vm.$set(_vm.vendor, "email", $$v)},expression:"vendor.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Téléphone de travail","label-for":"workPhone"}},[_c('validation-provider',{attrs:{"name":"workPhone","rules":"required|integer|length:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Téléphone de travail","maxlength":"8","type":"tel","formatter":_vm.formatPhone},model:{value:(_vm.vendor.work_phone),callback:function ($$v) {_vm.$set(_vm.vendor, "work_phone", $$v)},expression:"vendor.work_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Téléphone personnel","label-for":"mobile"}},[_c('validation-provider',{attrs:{"name":"Téléphone","rules":"integer|length:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Téléphone","maxlength":"8","type":"tel","formatter":_vm.formatPhone},model:{value:(_vm.vendor.mobile),callback:function ($$v) {_vm.$set(_vm.vendor, "mobile", $$v)},expression:"vendor.mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",class:{ 'disabled-cursor': _vm.isLoading || !_vm.isVendorValid },attrs:{"variant":"primary","type":"submit","disabled":_vm.isLoading || !_vm.isVendorValid},on:{"click":function($event){$event.preventDefault();return _vm.addVendor.apply(null, arguments)}}},[_vm._v(" Ajouter ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }