<template>
  <b-card-code title="Ajouter vendeur">
    <b-card-text>
      <span>Veuillez Saisir les Coordonnées de vendeur </span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Nom"
              label-for="nom"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  v-model="vendor.name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Email"
              label-for="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
              >
                <b-form-input
                  v-model="vendor.email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Email"
                  type="email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Téléphone de travail"
              label-for="workPhone"
            >
              <validation-provider
                #default="{ errors }"
                name="workPhone"
                rules="required|integer|length:8"
              >
                <b-form-input
                  v-model="vendor.work_phone"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Téléphone de travail"
                  maxlength="8"
                  type="tel"
                  :formatter="formatPhone"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Téléphone personnel"
              label-for="mobile"
            >
              <validation-provider
                #default="{ errors }"
                name="Téléphone"
                rules="integer|length:8"
              >
                <b-form-input
                  v-model="vendor.mobile"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Téléphone"
                  maxlength="8"
                  type="tel"
                  :formatter="formatPhone"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :class="{ 'disabled-cursor': isLoading || !isVendorValid }"
              :disabled="isLoading || !isVendorValid"
              @click.prevent="addVendor"
            >
              Ajouter
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
} from 'bootstrap-vue'
import { required } from '@validations'
import axios from 'axios'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      vendor: {
        name: '',
        work_phone: '',
        mobile: '',
        email: '',
      },
      required,
      isLoading: false,
    }
  },
  computed: {
    isVendorValid() {
      return (
        this.vendor.name !== ''
        && this.vendor.work_phone !== ''
      )
    },
  },
  methods: {
    formatPhone(e) {
      return String(e).substring(0, 8)
    },
    async addVendor() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            this.isLoading = true
            await axios.post('/api/vendors/create/', this.vendor)
            setTimeout(() => {
              this.showToast('success', 'top-center', 'Vendeur ajouté avec succés')
            }, 1000)
            this.isLoading = false
            this.$router.push('/vendors/')
          } catch (error) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', error.toString())
            }, 1000)
          }
        } else {
          setTimeout(() => {
            this.showToast('danger', 'top-center', 'champ obligatoire ! vérifier votre donnée !')
          }, 1000)
        }
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
